export default [
    {
      title: 'Users',
      icon: 'UsersIcon',
      children: [
        {
          title: 'Users List',
          route: 'users-index',
        },
        {
          title: 'New User',
          route: 'users-create',
        }
      ],
    },
  ]
  