export default [
    {
      title: 'Posts',
      icon: 'BookOpenIcon',
      children: [
        {
          title: 'Posts List',
          route: 'posts-index',
        },
        {
          title: 'New Post',
          route: 'posts-create',
        }
      ],
    },
  ]
  